.captcha-plane {
    margin-top: 5px;
    width: 100%;
    appearance: none;
    pointer-events: none;
    height: 60px;
}

.captcha-plane:focus {
    outline: none;
}

.captcha-plane::-webkit-slider-thumb {
    appearance: none;
    display: block;
    width: 50px;
    height: 40px;
    border-radius: 5px;

    background-color: #1890ff;
    background-image: url('../../assets/slider-icon.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-origin: content-box;
    padding: 5px;
    pointer-events: auto;

    transform-origin: 50% 50%;
    transform: scale(1);
    transition: transform ease-out 100ms;
    cursor: grab;
}

.captcha-plane::-moz-range-thumb {
    border: 0;
    display: block;
    width: 40px;
    height: 30px;
    border-radius: 5px;

    background-color: #1890ff;
    background-image: url('../../assets/slider-icon.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-origin: content-box;
    padding: 5px;
    pointer-events: auto;

    transform-origin: 50% 50%;
    transform: scale(1);
    transition: transform ease-out 100ms;
    cursor: grab;
}

.captcha-plane::-webkit-slider-runnable-track {
    height: 40px;
    padding: .25rem;
    box-sizing: content-box;
    border-radius: 5px;
    background-color: #DDE0E3;
}

.captcha-plane::-moz-range-track {
    height: 40px;
    padding: .25rem;
    box-sizing: content-box;
    border-radius: 5px;
    background-color: #DDE0E3;
}

.captcha-plane::-moz-focus-outer {
    border: 0;
}

.captcha-plane:disabled::-webkit-slider-thumb {
    background-color: #68D472;
}

.captcha-plane:disabled::-moz-range-thumb {
    background-color: #68D472;
}
